<template>
<div>
  <div class="full-width category-container">
    <div class="container">
      <div class="col-12 category-content">
        <div v-if="path.length">
          <Breadcrumbs :path="path" />
        </div>

        <h2 class="neue">Risultati di ricerca per: {{$route.params.q}}</h2>

        <CategoryView slug="$route.params.slug" :key="childKey" :cols="3" :filter="$route.params.q" :numItems="12" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import CategoryView from '@/components/category/View'

export default {
  name: 'Search',
  data: function () {
    return {
      path: [
        { label: 'Home', path: '/' },
        { label: 'Ricerca', path: '/' },
      ],
      cat: {},
      secondLevel: [],
      thirdLevel: {},
      childKey: 0
    }
  },
  watch: {
    $route() {
      this.childKey++
    }
  },

  mounted () {
    this.initToParam()
  },

  components: {
    Breadcrumbs,
    CategoryView
  },

  methods: {
    initToParam () { }
  }
}
</script>

<style scoped>
.category-container {
  margin-top: 38px;
  padding-bottom: 60px;
}

.category-content h2 {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}

@media screen and (max-width: 768px) {
  .category-content h2 {
    font-size: 28px;
    text-align: center;
  }
}

.category-content p {
  margin: 0 0 25px 0;
  max-width: 66.66666%;
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
}

.single-filter {
  width: 100%;
  padding-right: 50px;
}

.single-filter .filter-name {
  display: block;
  font-weight: 800;
  font-size: 13px;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.single-filter .filter-name:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
}

.single-filter .filter-content {
  padding-left: 40px;
  margin-bottom: 40px;
}

.single-filter .filter-content a {
  display: block;
  font-weight: 500;
  font-weight: 13px;
  margin-bottom: 12px;
}
</style>


